/* eslint-disable react/jsx-no-target-blank */
import React from "react"
import SEO from "../components/seo"
import useScript from "../hooks/use-script"
import { Link, withPrefix } from "gatsby"
import Layout from "../components/layout"
const DocumentSigning = (): JSX.Element => {
  useScript(withPrefix("js/show-on-scroll.js"))

  useScript(withPrefix("js/sticky-header.js"))

  useScript(withPrefix("animations/documentsigning/documentsigning.js"))

  useScript(withPrefix("animations/documentsigning/documentsigninglotte.js"))

  const schema = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebPage",
        "@id": "https://documentesign.com/document-signing/#webpage",
        url: "https://documentesign.com/document-signing/",
        name: "Free, Legally Binding Online Signatures",
        isPartOf: {
          "@type": "WebSite",
          "@id": "https://documentesign.com/#website",
        },
        primaryImageOfPage: {
          "@id":
            "https://documentesign.com/static/bcc56467c53b14453fd65055583f769a/home-benefits-img.jpg#primaryimage",
        },
        datePublished: "2021-02-16T07:56:57+00:00",
        dateModified: "2021-04-28T16:44:56+00:00",
        description:
          "Send, manage and sign documents, PDF from anywhere, on any device, or automate electronic signatures with document Esign document signing solution",
        inLanguage: "en-US",
        potentialAction: [
          {
            "@type": "ReadAction",
            target: ["https://documentesign.com/document-signing/"],
          },
        ],
      },
    ],
  }

  return (
    <>
      <Layout>
        <SEO
          title="Free, Legally Binding Online Signatures | Document eSign"
          description="Send, manage and sign documents, PDF from anywhere, on any device, or automate electronic signatures with document Esign document signing solution "
          schemaMarkup={schema}
        />
        <div className="feature-details doc-signing">
          <div className="intro show-on-scroll ani-in">
            <div className="container position-relative mb-md-n5">
              <div className="row">
                <div className="col-lg-10 offset-lg-1 text-center pt-lg-5">
                  <div className="h4 pt-5 pb-3">DOCUMENT SIGNING</div>
                  <h1 className="mb-lg-n4">
                    Close deals instantly with free, legally binding online
                    signatures
                  </h1>
                </div>
                <div className="col-md-6 offset-md-3 mt-md-n5 text-center">
                  <div
                    id="doumentsigning_lottie_container"
                    className="feature-lottie-ani"
                  ></div>
                </div>
              </div>
            </div>
            <div className="bg-shape d-none d-lg-block">
              <svg
                width="2560px"
                height="100px"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                preserveAspectRatio="none"
                x="0px"
                y="0px"
                viewBox="0 0 2560 100"
                xmlSpace="preserve"
                enableBackground="new 0 0 2560 100"
              >
                <polygon points="2560 0 2560 100 0 100"></polygon>
              </svg>
            </div>
          </div>
          <div className="container mt-md-5 pt-md-5">
            <div className="row pt-md-4">
              <div className="col-md-6 offset-md-3 text-center show-on-scroll ani-in">
                <p className="text-light-100 pt-4 pt-md-0">
                  Document esign offers reliable and scalable electronic
                  signature services that empowers you to{" "}
                  <Link to="/free-document-esign-online/">
                    sign documents online{" "}
                  </Link>
                  from anywhere, anytime and on any device. Our legally binding
                  electronic signatures facilitates every small enterprise and
                  big corporations to sign PDF and Word documents with our
                  digital signature online service. Document esign securely
                  approves, delivers, and signs documents online for a faster
                  turnaround time and boost in sales.
                </p>
                <a
                  href="https://app.documentesign.com/auth/signup"
                  target="_blank"
                  className="btn btn-dark px-md-5  mt-2 mb-2"
                >
                  Get Started
                </a>
                <br />
                <small className="text-muted">
                  Free forever, no credit card required
                </small>
              </div>
            </div>
          </div>
          <div className="container py-5">
            <div className="card features-bx mb-5">
              <img
                src={withPrefix("images/top-features-bg.svg")}
                className="img-fluid show-on-scroll ani-in"
                alt="..."
              />

              <div className="card-body px-lg-5 mt-md-n5 py-0">
                <div className="row">
                  <div className="col-sm-6 mb-4 mb-sm-5 show-on-scroll ani-in">
                    <div className="feature-icon mb-3">
                      <i className="fas fa-shield-alt"></i>
                    </div>
                    <div className="h4">Secure &amp; reliable</div>
                    <p className="text-light-100">
                      Document esign allows you to securely approve, send and
                      sign documents online. Our servers are encrypted and well
                      secured to your electronically signed document protected.
                      Once your session expires, we automatically remove all our
                      data from our servers.
                    </p>
                  </div>
                  <div className="col-sm-6 mb-4 mb-sm-5 show-on-scroll ani-in">
                    <div className="feature-icon mb-3">
                      <i className="fas fa-file-contract"></i>
                    </div>

                    <div className="h4">Sign and fill documents online</div>
                    <p className="text-light-100">
                      Our free digital signature online service allows you to
                      sign documents online along with adding text, initials,
                      dates and selecting checkboxes. Signing the documents is
                      as easy as click, tap or swipe.
                    </p>
                  </div>
                  <div className="col-sm-6 mb-4 mb-sm-5 show-on-scroll ani-in">
                    <div className="feature-icon mb-3">
                      <i className="fas fa-file-pdf"></i>
                    </div>
                    <div className="h4">Sign PDF, word, Img, Txt, Xls</div>
                    <p className="text-light-100">
                      Sign PDF and Word documents with our digital signature
                      services and request an electronic signature from up to
                      100 people. We also support different formats like Excel,
                      TXT and IMG and RTF files.
                    </p>
                  </div>
                  <div className="col-sm-6 mb-4 mb-sm-5 show-on-scroll ani-in">
                    <div className="feature-icon mb-3">
                      <i className="fas fa-cog"></i>
                    </div>

                    <div className="h4">Automate your documents</div>
                    <p className="text-light-100">
                      Our free document signer offers multiple ways to sign that
                      boost your efficiency by automating the signature and
                      manage the e-signing process from start to finish. You can
                      either draw, type on your device or upload an image of
                      your handwritten signature.
                    </p>
                  </div>
                </div>
              </div>
              <div className="feature-bx-cta text-center show-on-scroll ani-in">
                <Link to="/features/" className="btn btn-outline-dark">
                  Explore more features
                  <i className="fas fa-arrow-right ml-2"></i>
                </Link>
              </div>
              <img
                src={withPrefix("images/bottom-features-bg.svg")}
                className="img-fluid show-on-scroll ani-in"
                alt="..."
              />
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default DocumentSigning
